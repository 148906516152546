<template>
  <div>
    <div class="row page-title align-items-center">
      <div class="col-sm-4 col-xl-6">
        <h4 class="mb-1 mt-0">Landings</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100]"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <router-link
                    :to="{ name: 'business.landings.create' }"
                    class="btn btn-primary btn-sm mr-4"
                  >
                    <i class="uil uil-plus"></i> New Landing
                  </router-link>
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="datatable.queries.search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <button
                    class="ml-1 btn btn-primary btn-sm"
                    @click="getLandings"
                  >
                    <i class="uil uil-search-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!loadingLandings">
              <div class="table-responsive mb-0">
                <b-table
                  :items="landings"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :sort-direction="datatable.queries.sort_direction"
                  :per-page="datatable.queries.per_page"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(created_at)="data">
                    {{ data.item.created_at | datetime(`${$store.getters['auth/dateFormat']}`) }}
                  </template>
                  <template v-slot:cell(mobile_conversation_rate)="data">
                    {{ data.item.mobile_conversation_rate }}%
                  </template>
                  <template v-slot:cell(desktop_conversation_rate)="data">
                    {{ data.item.desktop_conversation_rate }}%
                  </template>
                  <template v-slot:cell(total_conversation_rate)="data">
                    {{ data.item.total_conversation_rate }}%
                  </template>
                  <template v-slot:cell(actions)="data">
                    <a :href="`${domainUrl}/pages/${data.item.link}/${user.business.id}`" target="_blank" class="btn btn-primary btn-sm">
                      <i class="uil uil-eye"></i>
                    </a>
                    <router-link
                      :to="{
                        name: 'business.landings.edit',
                        params: { id: data.item.id },
                      }"
                      class="btn btn-success btn-sm ml-1"
                    >
                      <i class="uil uil-edit"></i>
                    </router-link>
                    <b-button
                      variant="danger"
                      size="sm"
                      title="Delete"
                      class="ml-1"
                      :disabled="loadingRemove === data.item.id"
                      @click="destroy(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalLandings }} landings
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalLandings"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteModal" title="Delete campaign">
      <p v-if="selectedLanding"
        >Are you sure you want to delete landing "{{
          selectedLanding.title
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleDestroy">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'title', label: 'Name', sortable: true },
          { key: 'leads' },
          { key: 'mobile_traffic' },
          { key: 'mobile_conversation_rate' },
          { key: 'desktop_traffic' },
          { key: 'desktop_conversation_rate' },
          { key: 'total_conversation_rate' },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: false,
        },
      },
      showDeleteModal: false,
      selectedLanding: null,
      loadingLandings: false,
      loadingImpersonate: false,
      loadingRemove: null,
    }
  },

  computed: {
    landings() {
      let landings = this.$store.getters['landing/all'] || []

      return landings
    },

    totalLandings() {
      return this.$store.getters['landing/total']
    },

    whitelabelDomain() {
      return this.$store.getters['app/whitelabelDomain']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    config() {
      return this.$store.getters['app/config']
    },

    domainUrl() {
      let url = '';
      const  { whitelabel } = this.config
      if (this.config.custom_domain && whitelabel && whitelabel.is_ssl_cert_exists && whitelabel.is_vhost_exists) {
        url = `https://${this.config.custom_domain}`;
      } else {
        url = `https://${this.config.subdomain}.${this.config.whitelabel.domain}`
      }
      return url;
    }
  },

  mounted() {
    this.getLandings()
  },

  methods: {
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.sort_direction = args.sortDirection
      this.datatable.queries.page = 1
      this.getLandings()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getLandings()
    },

    getLandings() {
      this.loadingLandings = true

      this.$store
        .dispatch('landing/getAll', this.datatable.queries)
        .then(() => {
          this.loadingLandings = false
        })
        .catch(() => {
          this.loadingLandings = false
        })
    },

    destroy(landing) {
      this.showDeleteModal = true
      this.selectedLanding = landing
    },

    handleDestroy() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedLanding.id
      this.$store
        .dispatch('landing/remove', this.selectedLanding.id)
        .then(() => {
          this.loadingRemove = null
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
